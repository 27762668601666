<template>
  <div v-if="modal" class="modal">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-nColorButtonText bg-opacity-30 modal-backdrop"
    >
      <div class="modal-width bg-black rounded-3xl p-4">
        <div class="w-full flex items-start justify-end">
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
            alt="DIY Game Jam Remove Game"
            width="25"
            height="25"
            class="cursor-pointer mb-2"
            @click="closeModal"
          />
        </div>
        <iframe
          width="720"
          height="405"
          src="https://www.youtube.com/embed/WPzOnWoZw14?si=-pm17lm-m7vlnfKV"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameDataModal",
  components: {},
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style scoped>
.modal-width {
  width: 750px;
}
.mobile-height {
  height: 75%;
}
.modal-backdrop {
  backdrop-filter: blur(6px);
}
</style>
